import React, {useEffect, useState} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {usePaymentOrder} from 'context/payment-order-context'
import {client} from 'utils/api-client'
import {ErrorFallback} from 'components/lib'
import {translate} from '../utils/i10n'

const profileApiUrl = process.env.REACT_APP_PROFILE_API_URL

function PaymentOverview() {
  const {
    paymentOrder: {merchantId, ...rest},
  } = usePaymentOrder()

  const [state, setState] = useState({
    status: 'pending',
    merchant: null,
    error: null,
  })

  const {status, merchant, error} = state

  useEffect(() => {
    setState({status: 'pending'})
    client(`pay/merchant/${merchantId}`).then(
      // TODO: useClient
      merchant => {
        setState({status: 'resolved', merchant: merchant.data})
      },
      error => {
        setState({status: 'rejected', error})
      },
    )
  }, [merchantId])

  if (status === 'pending') {
    return <PaymentInfoFallback />
  } else if (status === 'rejected') {
    // this will be handled by an error boundary
    throw error
  } else if (status === 'resolved') {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, info) => client('error', {data: {error, info}}, profileApiUrl)}
      >
        <PaymentDataView
          paymentOrder={{business: merchant.firmName, ...rest}}
        />
      </ErrorBoundary>
    )
  }
}

function PaymentInfoFallback() {
  const fallbackPaymentInfoData = {
    amount: 'XXXX',
    currencyCode: '$',
    reference: 'xxxxxxxxx',
    concept: 'xxxxxxx',
  }
  return <PaymentDataView paymentOrder={fallbackPaymentInfoData} />
}

function PaymentDataView({
  paymentOrder: {amount, business = '', concept, currencyCode, reference},
}) {
  return (
    <>
      <div className="webreference amount-container">
        <div className="row">
          <div className="col-md-12">
            <div className="box-flex info-block headline">
              <p>{concept}</p>
            </div>
          </div>
        </div>
        <div className="row data">
          <div className="col-xs-12 col-sm-4 col-lg-6 details">
            <div className="col-md-12 col-sm-12 col-xs-6">
              <div className="info-block business">
                <p>{business}</p>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-6">
              <div className="info-block reference">
                <p>{reference}</p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-8 col-lg-6">
            {amount !== 0 ? (
              <div className="info-block price">
                <p>{translate`${amount} ${currencyCode}`}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="webreference copy">
        <div className="textura" />
        <div style={{padding: 10, textAlign: 'center'}}>{reference}</div>
      </div>
      <div className="arrow-down" />
    </>
  )
}

export default PaymentOverview