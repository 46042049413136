import React, {useEffect} from 'react'
import {client} from 'utils/api-client'
import {usePaymentOrder} from '../context/payment-order-context'
import {useAsync} from '../utils/hooks'
import {ErrorBoundary} from 'react-error-boundary'
import { Text } from "./lib";

const profileApiUrl = process.env.REACT_APP_PROFILE_API_URL

function MerchantInfo() {
  const {
    paymentOrder: {merchantId, uuid, shortCode},
  } = usePaymentOrder()

  const {run, isLoading, isError, isIdle, data: merchant} = useAsync()

  useEffect(() => {
    run(
      client(
        `${merchantId}/merchantFooterData.json`,
        {},
        process.env.REACT_APP_URL_S3_TEMPLATES,
      ),
    )
  }, [run, merchantId])

  function MerchantDataView({country, zipCode}) {
    return <Text>{`${country} ${zipCode}`}</Text>
  }

  function MerchantInfoFallback() {
    const merchantInfo = {country: 'xxxxxxx', zipCode: 'xxxxx'}
    return <MerchantDataView {...merchantInfo} />
  }

  if (isError || isIdle) {
    return null
  }
  if (isLoading) {
    return <MerchantInfoFallback />
  }

  return (
    <ErrorBoundary
      FallbackComponent={<MerchantInfoFallback />}
      onError={(error, info) =>
        client(
          'error',
          {data: {error: `${uuid} ${shortCode} ${error.message}`, info}},
          profileApiUrl,
        )
      }
    >
      <MerchantDataView country={merchant.country} zipCode={merchant.zipCode} />
    </ErrorBoundary>
  )
}

export {MerchantInfo}
