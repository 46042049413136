//TODO: Refactor tag name to i18n ???
const language = getBrowserLanguage()
const fallbackLanguage = 'en'

function translate(literalStrings, ...interpolations) {
  const translations = getTranslations(language.slice(0, 2))
  return literalStrings.reduce((fullString, literalString, index) => {
    let interpolation = String(interpolations[index] || '')
    if (interpolation.startsWith('t.')) {
      interpolation =
        translations[interpolation.slice(2)] ||
        translations['Generic error message']
    } else if (isNumberAsString(interpolation)) {
      interpolation = intlNumberFormat(interpolation, language)
    }
    return `${fullString}${literalString}${interpolation}`
  }, '')
}

function isNumberAsString(numberAsString) {
  return !isNaN(numberAsString)
}

export function intlNumberFormat(value, language) {
  return value
    ? new Intl.NumberFormat([language, fallbackLanguage]).format(value)
    : value
}

export function getTranslations(lang = fallbackLanguage) {
  try {
    return require(`../i18n/${lang}.json`)
  } catch (e) {
    return require(`../i18n/${fallbackLanguage}.json`)
  }
}

export function getBrowserLanguage() {
  return (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage
  )
}

export {translate}
