import React from 'react'

const MasterCardLogo = () => {
  return (
    <>
      <svg
        width="34"
        height="23"
        viewBox="0 0 34 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="34" height="23" rx="3" fill="#F5F5F5" />
        <path
          d="M20.1095 6.49719H13.8906V17.5027H20.1095V6.49719Z"
          fill="#FF5F00"
        />
        <path
          d="M14.2854 12C14.2854 9.76388 15.3515 7.78055 16.9901 6.49722C15.7858 5.56389 14.2657 5 12.6073 5C8.67854 5 5.5 8.13055 5.5 12C5.5 15.8694 8.67854 19 12.6073 19C14.2657 19 15.7858 18.4361 16.9901 17.5028C15.3515 16.2389 14.2854 14.2361 14.2854 12Z"
          fill="#EB001B"
        />
        <path
          d="M28.5001 12C28.5001 15.8694 25.3216 19 21.3928 19C19.7345 19 18.2143 18.4361 17.01 17.5028C18.6684 16.2194 19.7147 14.2361 19.7147 12C19.7147 9.76388 18.6486 7.78055 17.01 6.49722C18.2143 5.56389 19.7345 5 21.3928 5C25.3216 5 28.5001 8.15 28.5001 12Z"
          fill="#F79E1B"
        />
      </svg>
    </>
  )
}
export default MasterCardLogo
