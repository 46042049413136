import React, {useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {usePaymentOrder} from 'context/payment-order-context'
import {useAsync} from 'utils/hooks'
import {FullFormLoading} from 'components/lib'

function Pending() {
  const [searchParams] = useSearchParams()
  let navigate = useNavigate()
  const {execute, confirmPaymentRedirection: confirm} = usePaymentOrder()
  const {run, isSuccess, isError} = useAsync()

  useEffect(() => {
    const params = Object.fromEntries(searchParams)
    if (params?.confirmPayment) {
      run(confirm({...params}))
    } else {
      run(execute({threeDsData: {...params}}))
    }
  }, [])

  if (isError) {
    navigate(`/payment/end/error`) // FIXME: urlKo, urlok
  }

  if (isSuccess) {
    navigate('/payment/end/success') // FIXME: urlKo, urlok
  }

  return <FullFormLoading />
}

export {Pending}
