const VISA = 'visa'
const MASTERCARD = 'mastercard'
const MAESTRO = 'maestro'
const DEFAULT_MASK = '9999 9999 9999 9999'
const EXTENDED_MASK = '9999 9999 9999 9999 999'
const EXTENDED_CVV = 4
const DEFAULT_CVV = 3

function getCardBrandName(cardNumber) {
  if (/^4[0-9]{12}(?:[0-9]{3}){0,2}$/.test(cardNumber)) {
    return VISA
  }
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      cardNumber,
    )
  ) {
    return MASTERCARD
  }
  if (/^(5[06-8]|6\d)\d{10,17}$/.test(cardNumber)) {
    return MAESTRO
  }
  return ''
}

function getCardInputMask({isExtended}) {
  if (isExtended) {
    return EXTENDED_MASK
  }
  return DEFAULT_MASK
}

function getCvvMaxLength({isExtended}) {
  if (isExtended) {
    return EXTENDED_CVV
  }
  return DEFAULT_CVV
}

function removeStripSpaces(cardNumber) {
  return cardNumber.replace(/[ -]/g, '')
}

export {
  getCardBrandName,
  getCardInputMask,
  getCvvMaxLength,
  removeStripSpaces,
  VISA,
  MASTERCARD,
  MAESTRO,
}
