/** @jsx jsx */
import {jsx} from '@emotion/core'

import React from 'react'
import {usePaymentOrder} from 'context/payment-order-context'
import {PoweredByMymoid} from './powered-by-mymoid'
import {PaymentApp} from './screens/payment'
import {MerchantInfo} from './components/merchant-info' // TODO: different screen?
import {
  FullPageSpinner,
  FullPageErrorFallback,
  RowContainer,
} from 'components/lib'
import {translate} from './utils/i10n'
import {Profiler} from 'components/profiler'

function Header() {
  const {
    paymentOrder: {merchantId},
  } = usePaymentOrder()
  return (
    <header className="header">
      <div className="header-wrapper">
        <div className="content-header">
          <div className="navbar-brand">
            <img
              alt=""
              className=""
              src={`${process.env.REACT_APP_URL_S3_TEMPLATES}/${merchantId}/logo.png`}
            />
          </div>
        </div>
      </div>
    </header>
  )
}

function Footer({Content}) {
  return (
    <footer className="ng-scope">
      <div className="container">
        <div ng-if="merchantFooterData" className="row ng-scope">
          <RowContainer>
            <PoweredByMymoid />
          </RowContainer>
          <RowContainer>
            <div id="merchant-info" className="merchant-info ng-binding">
              {Content}
            </div>
          </RowContainer>
        </div>
      </div>
    </footer>
  )
}

const MerchantStyledApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ './merchant-styled-app'),
)
const DefaultStyledApp = React.lazy(() => import('./default-styled-app'))

function StyledApp({children}) {
  const {paymentOrder} = usePaymentOrder()
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {paymentOrder ? (
        <MerchantStyledApp children={children} paymentOrder={paymentOrder} />
      ) : (
        <DefaultStyledApp children={children} /> // FIXME: possible dead code
      )}
    </React.Suspense>
  )
}

function PaymentAppContainer({children}) {
  return (
    <div className="page">
      <div className="content">
        <div>
          <Header />
          <div
            className="container"
            style={{marginTop: '0 !important', paddingTop: '0 !important'}}
          >
            <div className="webreference-block">
              <div className="col-lg-6 col-lg-offset-3 col-md-offset-3 col-md-6 col-sm-8 col-sm-offset-2 col-xs-12">
                {children}
              </div>
            </div>
          </div>
          <Footer Content={<MerchantInfo />} />
        </div>
      </div>
    </div>
  )
}

function PaymentOrderNotAvailableCheck() {
  const {paymentOrder} = usePaymentOrder()
  return paymentOrder?.status === 'AVAILABLE' ||
    paymentOrder?.finisedPaymentFlow ||
    (paymentOrder?.status === 'PAID' && window.location.pathname !== '/') ? (
    <PaymentForm />
  ) : (
    <Profiler
      id="Payment order not available"
      metadata={{
        userAgent: window.navigator.userAgent,
        uuid: paymentOrder?.uuid,
        shortCode: paymentOrder?.shortCode,
      }}
    >
      <FullPageErrorFallback
        error={{
          message: translate`${'t.Payment order not available'}`,
        }}
      />
    </Profiler>
  )
}

function PaymentForm() {
  return (
    <StyledApp>
      <PaymentAppContainer>
        <PaymentApp />
      </PaymentAppContainer>
    </StyledApp>
  )
}

export default PaymentOrderNotAvailableCheck
