import {decode} from 'js-base64'
import {PARAMS_NAME_SUCCESS_URL, PARAMS_NAME_ERROR_URL} from '../utils/global'

function fromCentsToCurrency(amount) {
  return amount / 100
}

function formatExpireDate(date) {
  // TODO: test
  let list = date.split('/')
  let time = new Date()
  time.setDate(1)
  time.setMonth(parseInt(list[0], 10) - 1)
  time.setYear(parseInt('20' + list[1], 10))

  let day = time.getDate()
  let month = time.getMonth() + 1
  let year = time.getFullYear()

  if (month < 10) {
    return `${year}-0${month}-${day}`
  } else {
    return `${year}-${month}-${day}`
  }
}

function getRanDomArrayElement(array) {
  return array[Math.floor(Math.random() * array.length)]
}

function isUrlEncoded(url) {
  return decodeURIComponent(url) !== url
}

function removeParamFromSearchParams(params, paramName = 'p') {
  const decodeParams = new URLSearchParams(
    isUrlEncoded(params) ? decodeURIComponent(params) : params,
  )
  decodeParams.delete(paramName)

  return decodeParams.toString()
}

function paramsGettersConfigurator(pathname = '/') {
  let decoder
  if (pathname !== '/pending') {
    decoder = decodeURIComponent
    return {
      p: params => params.get('p'),
      paymentPoint: params => params.get('paymentPoint'),
      urlok: params => {
        const paramsWithoutP = removeParamFromSearchParams(
          params.toString(),
          'p',
        )
        const urlok = paramsWithoutP.match(/(urlok=\s*).*?(?=\s*&urlko|$)/gs)
        return urlok && decoder(urlok[0].replace('urlok=', ''))
      },
      urlko: params => {
        const paramsWithoutP = removeParamFromSearchParams(
          params.toString(),
          'p',
        )
        const urlko = paramsWithoutP.match(/(urlko=\s*).*?(?=\s*&urlok|$)/gs)
        return urlko && decoder(urlko[0].replace('urlko=', ''))
      },
      successURL: params => {
        const paramsWithoutP = removeParamFromSearchParams(
          params.toString(),
          'p',
        )
        const successURL = paramsWithoutP.match(
          /(successURL=\s*).*?(?=\s*&errorURL|$)/gs,
        )
        return successURL && decoder(successURL[0].replace('successURL=', ''))
      },
      errorURL: params => {
        const paramsWithoutP = removeParamFromSearchParams(
          params.toString(),
          'p',
        )
        const errorURL = paramsWithoutP.match(
          /(errorURL=\s*).*?(?=\s*&successURL|$)/gs,
        )
        return errorURL && decoder(errorURL[0].replace('errorURL=', ''))
      },
    }
  }
  decoder = decode
  return {
    p: searchParams => searchParams.get('p'),
    paymentPoint: params => params.get('paymentPoint'),
    urlok: searchParams => {
      const urlok = searchParams.get('urlok')
      if (!urlok) {
        return
      }
      const b64DecodedUrlok = decoder(urlok)
      return isUrlEncoded(b64DecodedUrlok)
        ? decodeURIComponent(b64DecodedUrlok)
        : b64DecodedUrlok
    },
    urlko: searchParams => {
      const urlko = searchParams.get('urlko')
      if (!urlko) {
        return
      }
      return isUrlEncoded(decoder(urlko))
        ? decodeURIComponent(decoder(urlko))
        : decoder(urlko)
    },
    successURL: searchParams => {
      const successURL = searchParams.get(PARAMS_NAME_SUCCESS_URL)
      if (!successURL) {
        return
      }
      return isUrlEncoded(decoder(successURL))
        ? decodeURIComponent(decoder(successURL))
        : decoder(successURL)
    },
    errorURL: searchParams => {
      const errorURL = searchParams.get(PARAMS_NAME_ERROR_URL)
      if (!errorURL) {
        return
      }
      return isUrlEncoded(decoder(errorURL))
        ? decodeURIComponent(decoder(errorURL))
        : decoder(errorURL)
    },
  }
}

function getPaymentFormParamsFromUrl({searchParams, paramsGetter}) {
  // FIXME: in future versions remove, consumers pass a valid encoded url
  let params = {}
  let shouldNavigateIntoFullpage = false

  Object.entries(paramsGetter).forEach(([name, getterFn]) => {
    params[name] = getterFn(searchParams)
  })

  if (
    Boolean(params[PARAMS_NAME_SUCCESS_URL]) ||
    Boolean(params[PARAMS_NAME_ERROR_URL])
  ) {
    shouldNavigateIntoFullpage = true
    params.urlok = params[PARAMS_NAME_SUCCESS_URL]
    params.urlko = params[PARAMS_NAME_ERROR_URL]
  }

  delete params[PARAMS_NAME_SUCCESS_URL]
  delete params[PARAMS_NAME_ERROR_URL]

  return {
    ...params,
    shouldNavigateIntoFullpage,
  }
}

function buildRedirectUrl(pathname = '/pending', searchParamsBuilderFn) {
  let redirectUrl = new URL(window.location.origin)
  redirectUrl.pathname = pathname

  if (searchParamsBuilderFn) {
    searchParamsBuilderFn(redirectUrl)
  }

  return redirectUrl.href
}

export {
  fromCentsToCurrency,
  formatExpireDate,
  getRanDomArrayElement,
  getPaymentFormParamsFromUrl,
  isUrlEncoded,
  removeParamFromSearchParams,
  buildRedirectUrl,
  paramsGettersConfigurator,
}
