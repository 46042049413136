import React from 'react'
import { MASTERCARD, VISA, MAESTRO } from "../utils/card";
import { VisaLogo, MasterCardLogo, MaestroLogo } from "../assets";

function LogoCardBrand(brand) {
  if(brand === MASTERCARD) return <MasterCardLogo />
  if(brand === VISA) return <VisaLogo />
  if(brand === MAESTRO) return <MaestroLogo />
  return null
}
export default LogoCardBrand;