const apiURL = process.env.REACT_APP_API_URL

const defaultFallBackMessage = {
  message: 'FIXME: Some error code or message',
}

async function client(
  endpoint,
  {data, token, headers: customHeaders, paymentPoint, ...customConfig} = {},
  baseUrl = apiURL,
  fallbackMessage = defaultFallBackMessage,
) {
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...(token && {Authorization: `Basic ${token}`}),
      'Content-Type': 'application/json',
      ...customHeaders,
    },
    ...customConfig,
  }

  return window
    .fetch(
      `${baseUrl}/${endpoint}${
        paymentPoint ? '?applicationId=' + paymentPoint : ''
      }`,
      config,
    )
    .then(async response => {
      if (response.status === 404) {
        // refresh the page for them
        // window.location.assign(window.location) // FIXME: why?
        return Promise.reject(fallbackMessage)
      }
      const data = await response.json()
      if (response.ok) {
        return data
      } else {
        return Promise.reject(data)
      }
    })
}

export {client, apiURL}
