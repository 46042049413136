import React from 'react'
import {translate} from '../utils/i10n'
import {FormErrorFallback} from '../components/lib'
import {ToUrl} from './payment'
import {Profiler} from '../components/profiler' // TODO: to lib maybe ....

function Success({paymentOrder}) {
  return (
    <Profiler
      id="Success"
      metadata={{
        userAgent: window.navigator.userAgent,
        uuid: paymentOrder?.uuid,
        shortCode: paymentOrder?.shortCode,
      }}
    >
      <FormErrorFallback // TODO: FormErrorFallback when success??
        copyTitle={() => translate`${'t.title_ok'}`}
        copyMessage={() => {
          return paymentOrder.amount !== 0
            ? translate`${'t.message_ok_pay'}`
            : translate`${'t.message_ok_validated'}`
        }}
        renderSubmit={() =>
          paymentOrder?.urlok ? (
            <Profiler
              id="Redirect to ko/ok"
              metadata={{
                userAgent: window.navigator.userAgent,
                uuid: paymentOrder?.uuid,
                urlok: paymentOrder?.urlok,
                shortCode: paymentOrder?.shortCode,
              }}
            >
              <ToUrl
                url={paymentOrder?.urlok}
                shouldNavigateIntoFullpage={
                  paymentOrder.shouldNavigateIntoFullpage
                }
              />
            </Profiler>
          ) : null
        }
      />
    </Profiler>
  )
}

export {Success}
