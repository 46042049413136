import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import {loadDevTools} from './dev-tools/load'
import './bootstrap'
import React from 'react'
import ReactDOM from 'react-dom'
import {v4 as uuidv4} from 'uuid'
import {Profiler} from './components/profiler'
import './widget'
import App from './app'
import * as serviceWorker from './serviceWorker'

const uuid = uuidv4()

loadDevTools(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Profiler
        id="App Root"
        metadata={{
          userAgent: window.navigator.userAgent,
          location: window.location.href,
          uuid,
        }}
        phases={['mount']}
      >
        <App uuid={uuid} />
      </Profiler>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
