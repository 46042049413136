import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {PaymentOrderProvider} from 'context/payment-order-context'

function AppProviders({
  uuid,
  shortCode,
  brandColor,
  isPaymentFormComponent,
  children,
}) {
  return (
    <Router>
      <PaymentOrderProvider
        uuid={uuid}
        shortCode={shortCode}
        brandColor={brandColor}
        isPaymentFormComponent={isPaymentFormComponent}
      >
        {children}
      </PaymentOrderProvider>
    </Router>
  )
}

export {AppProviders}
