import React, {useState, useEffect} from 'react'
import {wrap} from 'components/profiler'


function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false)
  React.useLayoutEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])
  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : void 0),
    [dispatch],
  )
}

const defaultInitialState = {status: 'idle', data: null, error: null}
function useAsync(initialState) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  })
  const [{status, data, error}, setState] = React.useReducer(
    (s, a) => ({...s, ...a}),
    initialStateRef.current,
  )

  const safeSetState = useSafeDispatch(setState)

  const setData = React.useCallback(
    data => safeSetState({data, status: 'resolved'}),
    [safeSetState],
  )
  const setError = React.useCallback(
    error => safeSetState({error, status: 'rejected'}),
    [safeSetState],
  )
  const reset = React.useCallback(() => safeSetState(initialStateRef.current), [
    safeSetState,
  ])

  const run = React.useCallback(
    promise => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`,
        )
      }
      safeSetState({status: 'pending'})
      return promise.then(
        wrap(data => {
          setData(data)
          return data
        }),
        wrap(error => {
          setError(error)
          return error
        }),
      )
    },
    [safeSetState, setData, setError],
  )

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  }
}

function useAutoRedirectionCountDown(
  redirectInTime,
  autoRedirectionUrl,
  shouldNavigateIntoFullpage,
) {
  const [countDown, setCountDown] = useState(redirectInTime)

  useEffect(() => {
    setTimeout(() => {
      shouldNavigateIntoFullpage
        ? window.top.location.replace(autoRedirectionUrl)
        : (window.location.href = autoRedirectionUrl)
    }, redirectInTime)

    const interval = setInterval(() => {
      setCountDown(countDown - 1000)
    }, 1000)

    return () => clearInterval(interval)
  }, [countDown])

  const seconds = countDown / 1000

  return [seconds]
}

export {useAsync, useAutoRedirectionCountDown}
