import React, {useEffect, useRef, useState} from 'react'
import {useNavigate, useLocation, Outlet, Routes, Route} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import {useForm} from 'react-hook-form'
import InputMask from 'react-input-mask'
import {usePaymentOrder} from '../context/payment-order-context'
import {useAsync, useAutoRedirectionCountDown} from '../utils/hooks'
import {
  getCardBrandName,
  removeStripSpaces,
  getCardInputMask,
  getCvvMaxLength,
} from '../utils/card'
import {translate} from '../utils/i10n'
import {trace} from 'components/profiler'
import {ErrorFallback, FullFormLoading, FormErrorFallback} from 'components/lib'
import {Pending} from './pending'
import PaymentOverview from './payment-info'
import {NotFoundScreen} from './not-found'
import {Success} from './success'
import {Profiler} from 'components/profiler'
import {client} from '../utils/api-client'
import {fromCentsToCurrency} from '../utils/misc'
import getLogoCardBrand from '../components/logo'
import {Adviser} from 'components/lib'
import {
  PaymentCheckout,
  PaymentMethodSelector,
  PaymentMethodSelectorItem,
  PaymentFormContainer,
  SubmitButton,
} from 'components/payment-styled'
import {MasterCardLogo, VisaLogo, BizumLogo} from 'assets'
import {NAVIGATION_TARGET_PARENT, NAVIGATION_TARGET_SELF} from '../utils/global'

// TODO: global???
const redirectionAuthenticationType = 'REDIRECTION'
const fingerprintAuthenticationType = 'FINGERPRINT'

const profileApiUrl = process.env.REACT_APP_PROFILE_API_URL
const apiURLV1 = process.env.REACT_APP_API_V1_URL
const apiUrl = process.env.REACT_APP_API_URL
const token = process.env.REACT_APP_API_TOKEN

const defaultRedirectInTime = 5000

const defaultPaymentMethod = 'CARD'

function ToUrl({
  url = window.location.href,
  shouldNavigateIntoFullpage = false,
}) {
  const [seconds] = useAutoRedirectionCountDown(
    defaultRedirectInTime,
    url,
    shouldNavigateIntoFullpage,
  )
  return (
    <div style={{minHeight: '72px'}}>
      <button
        className="btn btn-lg btn-inverse btn-primary btn-login btn-block"
        type="button"
        onClick={() => {
          trace(`Click Continue to url`, performance.now(), () => {
            shouldNavigateIntoFullpage
              ? window.top.location.replace(url)
              : (window.location.href = url)
          })
        }}
      >
        {translate`${'t.btnContinue'}`} {`(${seconds})`}
      </button>
    </div>
  )
}

function Redirect({
  action,
  method = 'POST',
  params = {},
  target = NAVIGATION_TARGET_SELF,
}) {
  const formEl = useRef(null)
  useEffect(() => {
    formEl.current.submit()
  }, [])
  return (
    <>
      <form
        style={{display: 'none'}}
        action={action}
        method={method}
        target={target}
        ref={formEl}
      >
        {Object.keys(params).map(key => (
          <input name={key} value={params[key]} />
        ))}
      </form>
      <FullFormLoading />
    </>
  )
}

function SubmitPaymentRedirect({
  action,
  method = 'POST',
  params = {},
  target = NAVIGATION_TARGET_SELF,
}) {
  const formEl = useRef(null)
  useEffect(() => {
    formEl.current.submit()
  }, [])
  return (
    <>
      <form
        style={{display: 'none'}}
        action={action}
        method={method}
        target={target}
        ref={formEl}
      >
        {params.map(key => (
          <input name={key.name} value={key.value} />
        ))}
      </form>
      <FullFormLoading />
    </>
  )
}

function FrictionLess() {
  const {
    paymentOrder: {paymentOrderId, threeDsData, paymentPoint},
  } = usePaymentOrder()
  let navigate = useNavigate()

  const [state, setState] = React.useState({
    status: 'pending',
    paymentOrder: null,
    error: null,
  })

  const {error, status} = state

  React.useEffect(() => {
    setState({status: 'pending'})
    const {
      p,
      urlok,
      urlko,
      successURL,
      errorURL,
      ...restThreeDsData
    } = threeDsData

    return client(
      `payments/${paymentOrderId}/execute`,
      {
        method: 'PUT',
        data: {
          ...restThreeDsData,
        },
        paymentPoint: paymentPoint?.appId,
        token,
      },
      apiURLV1,
    ).then(
      paymentOrder => {
        const {amount} = paymentOrder
        setState({
          status: 'resolved',
          paymentOrder: {
            amount: fromCentsToCurrency(amount), // FIXME: is it right change the amount from cents to currency in state? or when render in every place
            urlok,
            urlko,
            finisedPaymentFlow: true, // FIXME: trick
          },
        })
      },
      error => {
        setState({status: 'rejected', error})
      },
    )
  }, [paymentOrderId])

  if (status === 'resolved') {
    navigate('/payment/end/success')
  }

  if (status === 'rejected') {
    navigate(`/payment/end/error`, {
      state: error,
    })
  }

  return <FullFormLoading />
}

function FingerPrint({target = 'fingerprint_frame', ...props}) {
  const fingerprintReady = 'Ready'

  const {run, isError, isSuccess, isLoading, error} = useAsync()

  const {
    threeDsCompleteFingerprint,
    paymentOrder: {
      threeDsData,
      authenticationType,
      action: {httpMethod = 'POST', url, params} = {},
      shouldNavigateIntoFullpage,
    },
  } = usePaymentOrder()
  let navigate = useNavigate()

  const onMessageReceivedFromIframe = React.useCallback(
    event => {
      // FIXME: to event channel

      if (
        event.origin === new URL(apiUrl).origin &&
        event.data === fingerprintReady
      ) {
        run(threeDsCompleteFingerprint({threeDsData}))
      } else {
        return null // TODO: why???
      }
    },
    [threeDsCompleteFingerprint, run, threeDsData],
  )

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromIframe, false)
    return () => {
      window.removeEventListener('message', onMessageReceivedFromIframe)
    }
  }, [onMessageReceivedFromIframe])
  if (isSuccess) {
    return authenticationType === redirectionAuthenticationType ? (
      <Redirect
        action={url}
        method={httpMethod}
        params={params}
        target={
          shouldNavigateIntoFullpage
            ? NAVIGATION_TARGET_PARENT
            : NAVIGATION_TARGET_SELF
        }
      />
    ) : (
      <FrictionLess />
    )
  }

  if (isError) {
    // TODO: i dont know if is possible
    navigate(`/payment/end/error`, {
      state: error,
    })
  }

  if (isLoading) {
    return <FullFormLoading />
  }

  return (
    <>
      <Redirect target={target} {...props} />
      <iframe
        title="mymoid-fingerprint"
        name={target}
        height="0"
        width="0"
        style={{display: 'none'}}
      />
    </>
  )
}

function ExecutePayment() {
  const {
    paymentOrder: {
      shortCode,
      uuid,
      authenticationType,
      action: {httpMethod = 'POST', url, params} = {},
      shouldNavigateIntoFullpage,
    },
  } = usePaymentOrder()

  return (
    <Profiler
      id="Execute payment"
      metadata={{
        userAgent: window.navigator.userAgent,
        uuid,
        shortCode,
      }}
    >
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, info) =>
          client(
            'error',
            {data: {error: `${uuid} ${shortCode} ${error.message}`, info}},
            profileApiUrl,
          )
        }
      >
        {authenticationType === redirectionAuthenticationType ? (
          <Profiler
            id="Redirect payment"
            metadata={{
              userAgent: window.navigator.userAgent,
              uuid,
              shortCode,
            }}
          >
            <Redirect
              action={url}
              method={httpMethod}
              params={params}
              target={
                shouldNavigateIntoFullpage
                  ? NAVIGATION_TARGET_PARENT
                  : NAVIGATION_TARGET_SELF
              }
            />
          </Profiler>
        ) : authenticationType === fingerprintAuthenticationType ? (
          <Profiler
            id="Fingerprint payment"
            metadata={{
              userAgent: window.navigator.userAgent,
              uuid,
              shortCode,
            }}
          >
            <FingerPrint
              target="fingerprint_frame"
              action={url}
              method={httpMethod}
              params={params}
            />
          </Profiler>
        ) : (
          <Profiler
            id="FrictionLess payment"
            metadata={{
              userAgent: window.navigator.userAgent,
              uuid,
              shortCode,
            }}
          >
            <FrictionLess />
          </Profiler>
        )}
      </ErrorBoundary>
    </Profiler>
  )
}

function PaymentRedirection() {
  const {
    paymentOrder: {
      shortCode,
      shouldNavigateIntoFullpage,
      uuid,
      action: {actionUrl: url, method: httpMethod, parameters: params},
    },
  } = usePaymentOrder()
  return (
    <Profiler
      id="Execute payment redirection"
      metadata={{
        userAgent: window.navigator.userAgent,
        uuid,
        shortCode,
      }}
    >
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, info) =>
          client(
            'error',
            {data: {error: `${uuid} ${shortCode} ${error.message}`, info}},
            profileApiUrl,
          )
        }
      >
        <Profiler
          id="Redirect payment"
          metadata={{
            userAgent: window.navigator.userAgent,
            uuid,
            shortCode,
          }}
        >
          <SubmitPaymentRedirect
            action={url}
            method={httpMethod}
            params={params}
            target={
              shouldNavigateIntoFullpage
                ? NAVIGATION_TARGET_PARENT
                : NAVIGATION_TARGET_SELF
            }
          />
        </Profiler>
      </ErrorBoundary>
    </Profiler>
  )
}

function PaymentForm({
  paymentOrder: {supportScaGateway, moto},
  onCardSubmit,
  onApmSubmit,
  run,
  isLoading,
  isError,
  isSuccess,
  RenderSuccess,
}) {
  const {handleSubmit, register, errors, clearErrors} = useForm({
    reValidateMode: 'onSubmit',
  })
  let navigate = useNavigate()
  const [state, setState] = React.useReducer((s, a) => ({...s, ...a}), {
    holderName: '',
    cardNumber: '',
    cardBrand: '',
    expirationDate: '',
  })
  const [paymentMethodType, setPaymentMethodType] = useState(
    defaultPaymentMethod,
  )
  const {
    paymentOrder: {amount, currencyCode, uuid, shortCode, paymentPoint},
    brandColor,
  } = usePaymentOrder()
  const {holderName, cardNumber, cardBrand, expirationDate, cvv} = state
  const availablePaymentMethodTypes = paymentPoint?.availablePaymentMethodTypes

  useEffect(() => {
    const card = removeStripSpaces(cardNumber)
    setState({cardBrand: getCardBrandName(parseInt(card, 10))})
  }, [cardNumber])

  useEffect(() => {
    setPaymentMethodType(
      availablePaymentMethodTypes.includes(defaultPaymentMethod)
        ? defaultPaymentMethod
        : availablePaymentMethodTypes[0],
    )
  }, [])

  function handleSubmitDecorator() {
    trace(`Click pay`, performance.now(), () => {
      run(
        onCardSubmit({
          holderName,
          cardNumber,
          expirationDate,
          cvv,
          paymentPoint: paymentPoint?.appId,
        }),
      )
    })
  }

  function handleApmSubmitDecorator(type) {
    trace(`Click apm ${type}`, performance.now(), () => {
      run(onApmSubmit(type))
    })
  }

  // TODO: to utils
  function isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  if (isLoading) {
    return <FullFormLoading />
  }

  if (isError) {
    navigate(`/payment/end/error`)
  }

  if (isSuccess) {
    return <RenderSuccess />
  }

  const [fieldNameError] = Object.keys(errors)

  const shouldShowPaymentMethodSelector = Boolean(
    availablePaymentMethodTypes.length && amount !== 0,
  )
  const minAvailablePaymentMethod = 2

  return isEmpty(errors) ? (
    <>
      <Profiler
        id="Payment form Screen"
        metadata={{
          userAgent: window.navigator.userAgent,
          location: window.location.href,
          uuid,
          shortCode,
        }}
      >
        <div ng-hide="alert">
          {shouldShowPaymentMethodSelector && (
            <PaymentMethodSelector>
              {availablePaymentMethodTypes.map(type => (
                <PaymentMethodSelectorItem
                  key={type}
                  active={type === paymentMethodType}
                  onClick={() => setPaymentMethodType(type)}
                  disabled={
                    availablePaymentMethodTypes.length <
                    minAvailablePaymentMethod
                  }
                >
                  {/card/i.test(type) && (
                    <>
                      <MasterCardLogo />
                      <VisaLogo />
                    </>
                  )}
                  {/bizum/i.test(type) && <BizumLogo />}
                </PaymentMethodSelectorItem>
              ))}
            </PaymentMethodSelector>
          )}
          {/card/i.test(paymentMethodType) && (
            <div className="form data-form">
              <form
                className="form-group form-stacked-inline ng-pristine ng-invalid ng-invalid-required ng-hide"
                onSubmit={handleSubmit(handleSubmitDecorator)}
                noValidate
              >
                <div className="form-control stacked-input-1">
                  <input
                    className="ng-pristine ng-invalid ng-invalid-required"
                    id="name"
                    type="text"
                    name="name"
                    placeholder={translate`${'t.Holder Name'}`}
                    data-testid="card-holder-input"
                    required=""
                    value={holderName}
                    onChange={e => {
                      trace('Holder name changed', performance.now(), () => {
                        setState({holderName: e.target.value})
                      })
                    }}
                    maxLength="26"
                    ref={register({
                      required: translate`${'t.validateRequiredStart'} Holder Name ${'t.validateRequiredEnd'}`,
                    })}
                  />
                  <span className="form-icon icon-user-business"></span>
                </div>
                <div className="form-control stacked-input-1">
                  <InputMask
                    mask={getCardInputMask({isExtended: !supportScaGateway})}
                    value={cardNumber}
                    onChange={e => {
                      trace('Card number changed', performance.now(), () => {
                        setState({cardNumber: e.target.value})
                      })
                    }}
                    maskPlaceholder={null}
                  >
                    <input
                      id="cardNumberAnon"
                      type="tel"
                      name="cc-number"
                      placeholder={translate`${'t.Card Number'}`}
                      data-testid="card-number-input"
                      ref={register({
                        required: translate`${'t.validateRequiredStart'} Card Number ${'t.validateRequiredEnd'}`,
                        minLength: {
                          value: 19,
                          message: translate`${'t.validateFieldLengthStart'} Card Number ${'t.validateFieldLengthEnd'}`,
                        },
                      })}
                    />
                  </InputMask>
                  <span className="form-icon icon-card"></span>
                  <div id="icon-card-anon" className={`card-type`}>
                    {getLogoCardBrand(cardBrand)}
                  </div>
                </div>
                <div className="form-control stacked-input-1-2 inline">
                  <InputMask
                    mask="99/99"
                    value={expirationDate}
                    onChange={e => {
                      trace('cc-expire changed', performance.now(), () => {
                        setState({expirationDate: e.target.value})
                      })
                    }}
                    maskPlaceholder={null}
                  >
                    <input
                      className="ng-pristine ng-invalid ng-invalid-required ng-valid-minlength"
                      id="valid"
                      placeholder={translate`${'t.MM-YY'}`}
                      type="tel"
                      name="cc-expire"
                      data-testid="card-expire-input"
                      ref={register({
                        required: translate`${'t.validateRequiredStart'} expiration date ${'t.validateRequiredEnd'}`,
                        minLength: {
                          value: 5,
                          message: translate`${'t.validateRequiredStart'} expiration date ${'t.validateRequiredEnd'}`,
                        },
                      })}
                    />
                  </InputMask>
                  <span className="form-icon icon-calendar"></span>
                </div>
                <div className="form-control stacked-input-1-2 inline">
                  <input
                    className="ng-pristine ng-invalid ng-invalid-required ng-valid-minlength"
                    id="cvv"
                    name="cvv"
                    placeholder={translate`${'t.CVV'}`}
                    type="tel"
                    data-testid="card-cvv-input"
                    value={cvv}
                    onChange={e => {
                      trace('cvv changed', performance.now(), () => {
                        setState({cvv: e.target.value})
                      })
                    }}
                    maxLength={getCvvMaxLength({
                      isExtended: !supportScaGateway,
                    })}
                    ref={register({
                      required: translate`${'t.validateRequiredStart'} CVV ${'t.validateRequiredEnd'}`,
                      minLength: {
                        value: 3,
                        message: translate`${'t.validateFieldLengthStart'} cvv ${'t.validateFieldLengthEnd'}`,
                      },
                    })}
                  />
                  <span className="form-icon icon-lock"></span>
                </div>
                <div ng-if="order.amount != '0'" className="ng-scope">
                  <input
                    style={{
                      boxShadow: `0px -1px 0px 0px ${brandColor},
                        0px 1px 0px 0px ${brandColor},
                        0px 1px 2px 0px rgba(0,0,0,0.3)`,
                    }}
                    ref={el => {
                      if (el) {
                        el.style.setProperty(
                          'background-color',
                          brandColor,
                          'important',
                        )
                        el.style.setProperty(
                          'border-color',
                          brandColor,
                          'important',
                        )
                        el.style.setProperty('box-shadow', brandColor)
                      }
                    }}
                    className="btn btn-lg btn-inverse btn-primary btn-login btn-block"
                    type="submit"
                    value={
                      amount !== 0
                        ? translate`${'t.Pay'} ${amount} ${currencyCode}`
                        : translate`${'t.Validate card'}`
                    }
                    disabled={isLoading}
                  />
                </div>
              </form>
            </div>
          )}
          {/bizum/i.test(paymentMethodType) && (
            <PaymentFormContainer>
              <SubmitButton
                className="btn-primary"
                onClick={() => handleApmSubmitDecorator(paymentMethodType)}
              >
                {`${translate`${'t.Pay'}`} ${amount} ${currencyCode} ${translate`${'t.with Bizum'}`}`}
              </SubmitButton>
              <p>
                {translate`${"t.This button will redirect you to your bank's application or website"}`}
              </p>
            </PaymentFormContainer>
          )}
        </div>
      </Profiler>
      <Adviser
        showAdvise={supportScaGateway && !moto}
        text={translate`${'t.For security reasons, you may be redirected to the application of your bank.'}`}
        strongText={translate`${'t.Remember to return to this tab after validation.'}`}
      />
    </>
  ) : (
    <Profiler
      id="Payment form validation"
      metadata={{
        userAgent: window.navigator.userAgent,
        uuid,
        shortCode,
        error: errors?.[fieldNameError]?.message,
      }}
    >
      <FormErrorFallback
        copyTitle={() => translate`${'t.popupValidationErrorTitle'}`}
        copyMessage={() => errors[fieldNameError].message}
        renderSubmit={() => (
          <button
            className="btn btn-lg btn-inverse btn-primary btn-login btn-block"
            onClick={() => clearErrors()}
            type="button"
            ng-show="showButton"
          >
            {translate`${'t.btnText'}`}
          </button>
        )}
      />
    </Profiler>
  )
}

function PaymentRoutes() {
  const {
    threeDSAuthenticate,
    paymentOrder,
    anonymous,
    initPaymentRedirection,
  } = usePaymentOrder()
  const {state} = useLocation()
  const navigate = useNavigate()
  const {error, ...rest} = useAsync()
  // FIXME: prop drill error vs nagigate state 🤔

  return (
    <Routes>
      <Route path="/" element={<PaymentScreen />}>
        <Route
          index
          element={
            <PaymentForm
              paymentOrder={paymentOrder}
              paymentOrderId={paymentOrder.paymentOrderId}
              RenderSuccess={() => {
                if (paymentOrder.isPaymentRedirection) {
                  return <PaymentRedirection />
                }

                if (paymentOrder.supportScaGateway) {
                  return <ExecutePayment />
                }

                navigate(
                  // FIXME: better like render prop
                  `/payment/end/success`,
                )

                return null
              }}
              onCardSubmit={
                paymentOrder.supportScaGateway ? threeDSAuthenticate : anonymous
              }
              onApmSubmit={initPaymentRedirection}
              {...rest}
            />
          }
        />
        <Route
          path="payment/end/error"
          element={
            <ProfilePaymentFormErrorFallback
              uuid={paymentOrder?.uuid}
              shortCode={paymentOrder?.shortCode}
              urlko={paymentOrder?.urlko}
              error={error}
              state={state}
              paymentOrder={paymentOrder}
            />
          }
        />
        <Route
          path="payment/end/success"
          element={<Success paymentOrder={paymentOrder} />}
        />
        <Route
          path="/pending"
          element={
            <ProfilePending
              uuid={paymentOrder?.uuid}
              shortCode={paymentOrder?.shortCode}
              urlok={paymentOrder?.urlok}
              urlko={paymentOrder?.urlko}
            />
          }
        />
      </Route>
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  )
}

function ProfilePaymentFormErrorFallback({
  paymentOrder,
  uuid,
  shortCode,
  urlko,
  error,
  state,
}) {
  return (
    <Profiler
      id="Payment end error"
      metadata={{
        userAgent: window.navigator.userAgent,
        uuid,
        urlko,
        shortCode,
        error: error?.data?.[0]?.message || error,
        state: state?.data?.[0]?.message || state,
      }}
    >
      <FormErrorFallback
        copyTitle={() => translate`${'t.errorDefault'}`}
        copyMessage={() => {
          return error //FIXME: same cases the data structure is wrong (Generic error message)
            ? translate`${`t.${error?.data?.[0]?.message}`}`
            : state
            ? translate`${`t.${state?.data?.[0]?.message}`}`
            : translate`${`t.Generic error message`}`
        }} // TODO: error when refresh in error page
        renderSubmit={() =>
          urlko ? (
            <Profiler
              id="Redirect to ko/ok"
              metadata={{
                userAgent: window.navigator.userAgent,
                uuid,
                urlko,
                shortCode,
              }}
            >
              <ToUrl
                url={urlko}
                shouldNavigateIntoFullpage={
                  paymentOrder.shouldNavigateIntoFullpage
                }
              />
            </Profiler>
          ) : null
        }
      />
    </Profiler>
  )
}

function ProfilePending({uuid, shortCode, urlok, urlko}) {
  return (
    <Profiler
      id="Pending"
      metadata={{
        userAgent: window.navigator.userAgent,
        uuid,
        shortCode,
        urlok,
        urlko,
      }}
    >
      <Pending />
    </Profiler>
  )
}

function PaymentScreen() {
  return (
    <>
      <PaymentOverview />
      <PaymentCheckout className="webreference custom-style">
        <Outlet />
      </PaymentCheckout>
    </>
  )
}

function PaymentApp() {
  const {paymentOrder} = usePaymentOrder()
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) =>
        client(
          'error',
          {
            data: {
              error: `${paymentOrder.uuid} ${paymentOrder.shortCode} ${error.message}`,
              info,
            },
          },
          profileApiUrl,
        )
      }
    >
      <PaymentRoutes />
    </ErrorBoundary>
  )
}

export {PaymentApp, ToUrl}
