import * as zoid from 'zoid/dist/zoid.frameworks'

let paymentForm = zoid.create({
  tag: 'payment-form',
  url: ({props}) => {
    console.log(
      `Payment form component config in web application dev: ${props.dev}`,
    )
    return props.dev ? 'http://localhost:3000' : 'https://pre-tpv.mymoid.com' // TODO: change dns to payment-form.mymoid.com
  },
  autoResize: {
    height: true,
    width: false,
  },
})

// console.log('yo! have loaded mywidget from child: ')
// console.log(paymentForm)

export default paymentForm
