/** @jsx jsx */
import {jsx} from '@emotion/core'
import styled from '@emotion/styled/macro'
import {Link as RouterLink} from 'react-router-dom'
import {ReactComponent as IconAlert} from './icons/icon-info-positive.svg'
import * as mq from 'styles/media-queries'
import * as colors from 'styles/colors'
import {translate} from '../utils/i10n'

function FullPageAlert({alert, description, MoreInfoLink}) {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        role="alert"
        css={{
          color: colors.text,
          height: 300,
          width: 300,
        }}
      >
        <h5 css={{fontSize: 14, marginBlockEnd: 17}}>{alert}</h5>
        <p css={{fontSize: 15}}>{description}</p>
        {MoreInfoLink ? <MoreInfoLink /> : null}
      </div>
    </div>
  )
}

function FullPageSpinner() {
  return (
    <div className="container-minimum">
      <div className="col-minimum-lg-6 col-minimum-lg-offset-3 col-minimum-md-offset-3 col-minimum-md-6 col-minimum-sm-8 col-minimum-sm-offset-2 col-minimum-xs-12">
        <div className="webreference-minimum">
          <div className="alerta-minimum ng-hide">
            <div className="alerta-container-minimum ng-hide">
              <div className="copy-container-minimum">
                <div className="copy-minimum" ng-hide="errorShow">
                  <h2 className="ng-binding" data-testid="full-form-loading">
                    {translate`${`t.Just a moment! Your payment order is loading...`}`}
                  </h2>
                  <h3>{translate`${'t.Please do not close this windows.'}`}</h3>
                </div>
              </div>
            </div>
            <div className="loading-minimum">
              <FormSpinner />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function FullPageErrorFallback({error}) {
  return (
    <div
      role="alert"
      css={{
        color: colors.danger,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre style={{backgroundColor: 'transparent'}}>{error.message}</pre>
    </div>
  )
}

const errorMessageVariants = {
  stacked: {display: 'block'},
  inline: {display: 'inline-block'},
}

function ErrorMessage({error, variant = 'stacked', ...props}) {
  return (
    <div
      role="alert"
      css={[{color: colors.danger}, errorMessageVariants[variant]]}
      {...props}
    >
      <span>There was an error: </span>
      <pre
        css={[
          {whiteSpace: 'break-spaces', margin: '0', marginBottom: -5},
          errorMessageVariants[variant],
        ]}
      >
        {error.message}
      </pre>
    </div>
  )
}

function FormSpinner() {
  return (
    <div className="btn-block custom-style loading" ng-hide="errorShow">
      <div className="spinner">
        <svg
          height="70px"
          id="loader-1"
          style={{enableBackground: 'new 0 0 50 50'}}
          version="1.1"
          viewBox="0 0 50 50"
          width="70px"
          x="0px"
          xmlns="http://www.w3.org/2000/svg"
          y="0px"
        >
          <path
            d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
            fill="#000"
            transform="rotate(139.399 25 25)"
          >
            <animateTransform
              attributeName="transform"
              attributeType="xml"
              dur="0.6s"
              from="0 25 25"
              repeatCount="indefinite"
              to="360 25 25"
              type="rotate"
            ></animateTransform>
          </path>
        </svg>
      </div>
    </div>
  )
}

function FullFormLoading() {
  return (
    // TODO: Remove extended style. Maybe add to "alerta" styles
    <div className="alerta ng-hide" style={{backgroundColor: '#fff'}}>
      <div className="alert-container ng-hide">
        <div className="copy-container">
          <div className="copy" ng-hide="errorShow">
            <h2
              className="ng-binding"
              data-testid="full-form-loading"
              style={{
                whiteSpace: 'pre-line',
                marginBottom: 0,
                fontWeight: 'bold',
                lineHeight: '150%',
              }}
            >
              {translate`${`t.Just a moment! We are processing your payment...`}`}
            </h2>
            <h3
              style={{fontWeight: 'lighter', marginTop: 0}}
            >{translate`${'t.Please do not close this windows.'}`}</h3>
          </div>
        </div>
      </div>
      <FormSpinner />
    </div>
  )
}

function ErrorFallback({error}) {
  return (
    <ErrorMessage
      error={error}
      css={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  )
}

// eslint-disable-next-line
function FormErrorFallback({
  copyTitle = () => '',
  copyMessage = () => '',
  renderSubmit = null,
}) {
  return (
    // TODO: Remove extended style. Maybe add to "alerta" styles
    <div className="alerta" style={{backgroundColor: '#fff'}}>
      <div className="alert-container">
        <div className="copy-container">
          <div className="copy" style={{borderBottom: '1px dotted #dadada'}}>
            <h2>{copyTitle()}</h2>
          </div>
          <div className="arrow-down"></div>
          <div className="copy" role="alert">
            <h2>{copyMessage()}</h2>
          </div>
        </div>
      </div>
      <div style={{minHeight: '72px'}}>{renderSubmit()}</div>
    </div>
  )
}

function Adviser({showAdvise = false, text, strongText}) {
  if (showAdvise) {
    return (
      <AdviseContainer>
        <IconAlert />
        <TextContainer>
          <Text>{text}</Text>
          <StrongText>{strongText}</StrongText>
        </TextContainer>
      </AdviseContainer>
    )
  }
  return null
}

const AdviseContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  background: colors.base,
  border: `1px solid ${colors.green}`,
  boxSizing: 'border-box',
  padding: '5px',
  marginTop: '6px',
})

const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginLeft: '4px',
  color: colors.text,
})

const Text = styled.div({
  [mq.small]: {
    fontWeight: '400',
    fontSize: '13px',
  },
  [mq.extraSmall]: {
    fontWeight: '300',
    fontSize: '12px',
  },
  [mq.extraExtraSmall]: {
    fontWeight: '200',
    fontSize: '12px',
  },
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '17px',
  flexGrow: '1',
})

const StrongText = styled.div({
  [mq.small]: {
    fontWeight: '700',
    fontSize: '13px',
  },
  [mq.extraSmall]: {
    fontWeight: '600',
    fontSize: '12px',
  },
  [mq.extraExtraSmall]: {
    fontWeight: '600',
    fontSize: '12px',
  },
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '17px',
  marginTop: '2px',
})

const Link = styled(RouterLink)({
  color: colors.indigo,
  ':hover': {
    color: colors.indigoDarken10,
    textDecoration: 'underline',
  },
})
const OutOfAppLink = styled.a({
  color: colors.text,
  fontSize: 14,
  ':hover': {
    color: colors.text,
  },
})

function RowContainer({children}) {
  return (
    <div className="container" style={{margin: 'none', padding: 'none'}}>
      <div style={{marginTop: '13px'}}>
        <div className="col-lg-6 col-lg-offset-3 col-md-offset-3 col-md-6 col-sm-8 col-sm-offset-2 col-xs-12">
          {children}
        </div>
      </div>
    </div>
  )
}

export {
  Adviser,
  FullPageAlert,
  FullFormLoading,
  FullPageSpinner,
  FullPageErrorFallback,
  FormErrorFallback,
  ErrorMessage,
  ErrorFallback,
  Link,
  OutOfAppLink,
  RowContainer,
  Text,
}
