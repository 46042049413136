import React from 'react'
import {useSearchParams, useLocation} from 'react-router-dom'
import {encodeURL} from 'js-base64'
import {useAsync} from 'utils/hooks'
import * as paymentOrderProvider from 'payment-order-provider'
import {client} from 'utils/api-client'
import {FullPageSpinner, FullPageErrorFallback} from 'components/lib'
import {
  fromCentsToCurrency,
  formatExpireDate,
  getPaymentFormParamsFromUrl,
  buildRedirectUrl,
  paramsGettersConfigurator,
} from '../utils/misc'
import {translate} from '../utils/i10n'
import {wrap} from '../components/profiler'
import {PARAMS_NAME_ERROR_URL, PARAMS_NAME_SUCCESS_URL} from 'utils/global'

const PaymentOrderContext = React.createContext()
PaymentOrderContext.displayName = 'PaymentOrderContext'

const findPaymentOrderFailMessage = {
  message: translate`${'t.Payment order not available'}`,
}

async function bootstrapAppData(
  shortCode,
  nextUrlok,
  nextUrlko,
  paymentPointUrl,
  uuid,
  shouldNavigateIntoFullpage,
) {
  let paymentOrder = paymentOrderProvider.getPaymentOrder(shortCode)

  if (!paymentOrder) {
    //TODO: to react-query
    const data = await client(
      `pay/order/tpv/find?shortCode=${shortCode}`,
      {
        token: process.env.REACT_APP_API_TOKEN,
      },
      undefined,
      findPaymentOrderFailMessage,
    )
    paymentOrder = data.data
    paymentOrder.amount = fromCentsToCurrency(paymentOrder.amount)
  }

  const applicationId = paymentPointUrl
    ? paymentPointUrl
    : paymentOrder?.paymentPoint?.appId

  const {
    data: {availablePaymentMethodTypes},
  } = await paymentOrderProvider.getAvailablePaymentMethodTypes(applicationId)

  if (paymentOrder?.paymentPoint?.appId) {
    paymentOrder.paymentPoint.appId = applicationId
  }
  paymentOrder.uuid = uuid
  paymentOrder.shouldNavigateIntoFullpage = shouldNavigateIntoFullpage

  const cardMethodType = availablePaymentMethodTypes?.find(
    availablePaymentMethodType =>
      availablePaymentMethodType?.paymentMethodType === 'CARD',
  )

  paymentOrder.supportScaGateway =
    (cardMethodType?.['3ds'] || cardMethodType?.moto) ??
    paymentOrder.supportScaGateway

  paymentOrder.moto = cardMethodType?.moto ?? paymentOrder?.moto

  return paymentOrderProvider.setPaymentOrder({
    ...paymentOrder,
    paymentPoint: {
      ...paymentOrder.paymentPoint,
      availablePaymentMethodTypes: availablePaymentMethodTypes.map(
        ({paymentMethodType}) => paymentMethodType,
      ),
    },
    urlok: nextUrlok ? nextUrlok : paymentOrder.urlok, // TODO: maybe nullish coalescing
    urlko: nextUrlko ? nextUrlko : paymentOrder.urlko,
  }) //TODO: to react-query
}

function PaymentOrderProvider(props) {
  const {shortCode, brandColor} = props // FIXME: state colocation, brand useMemo?
  const {
    data: paymentOrder,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync()

  let [searchParams] = useSearchParams() // FIXME: https://github.com/ReactTraining/react-router/blob/f59ee5488bc343cf3c957b7e0cc395ef5eb572d2/packages/react-router-dom/index.js#L173
  const location = useLocation()

  React.useEffect(() => {
    const {
      p = null,
      urlko = null,
      urlok = null,
      paymentPoint = null,
      shouldNavigateIntoFullpage = false,
    } = getPaymentFormParamsFromUrl({
      // FIXME: usePaymentFormParams
      searchParams,
      paramsGetter: paramsGettersConfigurator(location.pathname),
    })
    const appDataPromise = bootstrapAppData(
      shortCode ? shortCode : p,
      urlok,
      urlko,
      paymentPoint,
      props.uuid,
      shouldNavigateIntoFullpage,
    )
    run(appDataPromise)
  }, [run]) // eslint-disable-line react-hooks/exhaustive-deps

  const redirectUrl = (params = []) => {
    return buildRedirectUrl('/pending', nextLocationHref => {
      const currentLocation = new URL(window.location.href)
      if (currentLocation.searchParams.has('paymentPoint')) {
        nextLocationHref.searchParams.set(
          'paymentPoint',
          paymentOrder?.paymentPoint?.appId,
        )
      }
      if (currentLocation.searchParams.has('urlko')) {
        nextLocationHref.searchParams.set(
          'urlko',
          encodeURL(paymentOrder.urlko),
        )
      }
      if (currentLocation.searchParams.has('urlok')) {
        nextLocationHref.searchParams.set(
          'urlok',
          encodeURL(paymentOrder.urlok),
        )
      }
      if (currentLocation.searchParams.has(PARAMS_NAME_ERROR_URL)) {
        nextLocationHref.searchParams.set(
          PARAMS_NAME_ERROR_URL,
          encodeURL(paymentOrder.urlko),
        )
      }
      if (currentLocation.searchParams.has(PARAMS_NAME_SUCCESS_URL)) {
        nextLocationHref.searchParams.set(
          PARAMS_NAME_SUCCESS_URL,
          encodeURL(paymentOrder.urlok),
        )
      }
      nextLocationHref.searchParams.set('p', paymentOrder.shortCode)
      Boolean(params.length) &&
        params.forEach(param =>
          nextLocationHref.searchParams.set(param.key, param.value),
        )
    })
  }

  const threeDSAuthenticate = React.useCallback(
    ({holderName, cardNumber, expirationDate, cvv, paymentPoint}) => {
      // FIXME: in future versions remove, consumers pass a valid encoded url
      return paymentOrderProvider
        .threeDSAuthenticate({
          paymentOrderId: paymentOrder.paymentOrderId,
          holderName,
          cardNumber: cardNumber.replace(/\s/g, ''),
          expirationDate: formatExpireDate(expirationDate),
          cvv,
          paymentPoint,
          redirectUrl: redirectUrl(),
        })
        .then(payment => {
          setData(payment)
        })
    },
    [paymentOrder, setData],
  )

  const threeDsCompleteFingerprint = React.useCallback(
    overrides => {
      const {paymentOrderId, threeDsData} = paymentOrder
      return paymentOrderProvider
        .threeDsCompleteFingerprint({
          paymentOrderId,
          threeDsData,
          timeoutFinished: true,
          ...overrides,
        })
        .then(completePaymentData => setData(completePaymentData))
    },
    [paymentOrder, setData],
  )

  const execute = React.useCallback(
    overrides => {
      const {paymentOrderId, threeDsData} = paymentOrder

      return paymentOrderProvider
        .execute({paymentOrderId, threeDsData, ...overrides})
        .then(payment => setData(payment))
    },
    [paymentOrder, setData],
  )

  const anonymous = React.useCallback(
    ({holderName, cardNumber, expirationDate, cvv, paymentPoint}) => {
      return paymentOrderProvider
        .anonymous({
          paymentOrderId: paymentOrder.paymentOrderId,
          holderName,
          cardNumber: cardNumber.replace(/\s/g, ''),
          expirationDate: formatExpireDate(expirationDate),
          cvv,
          paymentPoint,
        })
        .then(wrap(payment => setData(payment)))
    },
    [paymentOrder, setData],
  )

  const initPaymentRedirection = React.useCallback(
    type =>
      paymentOrderProvider
        .initPaymentRedirection({
          type,
          redirectUrl: redirectUrl([{key: 'confirmPayment', value: true}]),
          paymentOrderId: paymentOrder.paymentOrderId,
          paymentPoint: paymentOrder?.paymentPoint?.appId,
        })
        .then(payment => setData(payment)),
    [paymentOrder, setData],
  )

  const confirmPaymentRedirection = React.useCallback(() => {
    const {paymentOrderId, paymentPoint} = paymentOrder

    return paymentOrderProvider
      .confirmPaymentRedirection({
        paymentOrderId,
        paymentPoint: paymentPoint?.appId,
      })
      .then(payment => setData(payment))
  }, [paymentOrder, setData])

  const value = React.useMemo(
    () => ({
      anonymous,
      execute,
      paymentOrder,
      threeDSAuthenticate,
      threeDsCompleteFingerprint,
      brandColor,
      initPaymentRedirection,
      confirmPaymentRedirection,
    }),
    [
      anonymous,
      execute,
      paymentOrder,
      threeDSAuthenticate,
      threeDsCompleteFingerprint,
      brandColor,
      initPaymentRedirection,
      confirmPaymentRedirection,
    ],
  )

  if (isLoading || isIdle) {
    return <FullPageSpinner />
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />
  }
  if (isSuccess) {
    return <PaymentOrderContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function usePaymentOrder() {
  const context = React.useContext(PaymentOrderContext)
  if (context === undefined) {
    throw new Error(
      `usePaymentOrder must be used within a PaymentOrderProvider`,
    )
  }
  return context
}

export {usePaymentOrder, PaymentOrderProvider}
