import styled from '@emotion/styled/macro'

export const PaymentMethodSelector = styled.div`
  display: flex;
  flex-direction: row;
`
export const PaymentMethodSelectorItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: transparent;
  border: 0;
  padding: 1.5rem;
  border-top: 0.3rem solid;
  border-color: ${props => (props.active ? '#33b407' : 'transparent')};
  transition: border-color 150ms ease-in-out;
  svg {
    zoom: 125%;
    opacity: ${props => (props.active ? '1' : '0.5')};
    transition: opacity 150ms ease-in-out;
    &:not(:first-of-type) {
      margin-left: 0.5rem;
    }
  }
`
export const PaymentCheckout = styled.div`
  overflow: hidden;
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
`

export const PaymentFormContainer = styled.div`
  padding: 4rem 2rem;
  text-align: center;
`

export const SubmitButton = styled.button`
  font-size: 1.2em;
  margin: 1rem;
  width: 100%;
  background-color: ${props => props.bgColor} !important;
  box-shadow: 0px -1px 0px 0px rgb(0 0 0 / 30%),
    0px 1px 0px 0px rgb(0 0 0 / 30%), 0px 1px 2px 0px rgb(0 0 0 / 30%);
  height: 4.25em;
  @media screen and (max-width: 768px) {
    height: 3em;
  }
`
