import React from 'react'
import {AppProviders} from './context'
import PaymentForm from './payment-form'
import {Profiler} from './components/profiler'

function useXProps() {
  // FIXME: state colocation, side effect like localstorage?
  const [xprops, setXProps] = React.useState(() => window.xprops)

  React.useEffect(() => {
    window?.xprops?.window?.xprops?.onProps(props => {
      setXProps({...props, isPaymentFormComponent: true})
    })
  }, [])
  return xprops
}

function CookiesDiabledLog({uuid, children}) {
  const cookiesEnabled = navigator.cookieEnabled
  return cookiesEnabled ? (
    children
  ) : (
    <Profiler
      id="Cookies disabled"
      metadata={{
        location: window.location.href,
        uuid,
      }}
    >
      {children}
    </Profiler>
  )
}

function App({uuid}) {
  const {...props} = useXProps() || {} // FIXME: state colocation
  return (
    <CookiesDiabledLog uuid={uuid}>
      <AppProviders uuid={uuid} {...props}>
        <PaymentForm />
      </AppProviders>
    </CookiesDiabledLog>
  )
}

export default App
