import {client} from 'utils/api-client'
import {fromCentsToCurrency} from './utils/misc'

let inMemoryPaymentOrder

function getPaymentOrder(shortCode) {
  const savedPaymentOrder = inMemoryPaymentOrder
  if (
    shortCode &&
    savedPaymentOrder &&
    shortCode !== savedPaymentOrder.shortCode
  ) {
    return null
  }
  return savedPaymentOrder
}

function setPaymentOrder(paymentOrder) {
  inMemoryPaymentOrder = paymentOrder
  return inMemoryPaymentOrder
}

function handlePaymentOrderResponse({data}) {
  const paymentOrderData = {...getPaymentOrder(), ...data}
  return setPaymentOrder(paymentOrderData) //  TODO: to react query
}

function handlePaymentFlowFinished({data}) {
  const {amount, ...rest} = data
  const {urlok, urlko} = getPaymentOrder()
  return {
    amount: fromCentsToCurrency(amount), // FIXME: is it right change the amount from cents to currency in state? or when render in every place
    ...rest,
    urlok,
    urlko,
    finisedPaymentFlow: true, // FIXME: trick
  } //  TODO: to react query
}

function handleInitRedirectionPaymentResponse({data}) {
  const paymentOrderData = {
    ...getPaymentOrder(),
    isPaymentRedirection: true,
    action: data,
  }
  return setPaymentOrder(paymentOrderData) //  TODO: to react query
}

const apiURLV1 = process.env.REACT_APP_API_V1_URL
const apiURL = process.env.REACT_APP_API_URL
const token = process.env.REACT_APP_API_TOKEN

function threeDSAuthenticate({
  paymentOrderId,
  holderName,
  cardNumber,
  expirationDate,
  cvv,
  redirectUrl,
  paymentPoint,
}) {
  return client(
    // TODO: to useClient hook
    `payments/3ds-authentication`,
    {
      method: 'PUT',
      data: {
        paymentOrderId,
        cardData: {
          holderName,
          cardNumber,
          expirationDate,
          cvv,
        },
        redirectUrl,
      },
      paymentPoint,
      token,
    },
    apiURLV1,
  ).then(handlePaymentOrderResponse)
}

function threeDsCompleteFingerprint({
  paymentOrderId,
  timeoutFinished = false,
  paymentPoint,
  threeDsData,
}) {
  return client(
    // TODO: to useClient hook
    `payments/3ds-complete-fingerprint`,
    {
      method: 'PUT',
      data: {
        paymentOrderId,
        timeoutFinished,
        threeDsData,
      },
      paymentPoint,
      token,
    },
    apiURLV1,
  ).then(handlePaymentOrderResponse)
}

function execute({paymentOrderId, threeDsData}) {
  const {
    p,
    urlok,
    urlko,
    successURL,
    errorURL,
    paymentPoint,
    ...restThreeDsData
  } = threeDsData
  return client(
    `payments/${paymentOrderId}/execute`,
    {
      method: 'PUT',
      data: {
        ...restThreeDsData,
      },
      paymentPoint,
      token,
    },
    apiURLV1,
  ).then(handlePaymentFlowFinished)
}

function anonymous({
  paymentOrderId,
  holderName,
  cardNumber,
  expirationDate,
  cvv,
  paymentPoint,
}) {
  return client(
    // TODO: to useClient hook
    `pay/order/execute/${paymentOrderId}/anonymous`,
    {
      method: 'PUT',
      data: {
        holderName,
        cardNumber,
        expirationDate,
        cvv,
      },
      paymentPoint,
      token,
    },
    apiURL,
  ).then(handlePaymentFlowFinished)
}

function initPaymentRedirection({
  type,
  redirectUrl,
  paymentOrderId,
  paymentPoint,
}) {
  return client(
    // TODO: to useClient hook
    `pay/order/${paymentOrderId}/begin-execution`,
    {
      method: 'POST',
      data: {
        details: {
          type,
          redirectOkUrl: redirectUrl,
          redirectKoUrl: redirectUrl,
        },
      },
      paymentPoint,
      token,
    },
    apiURL,
  ).then(handleInitRedirectionPaymentResponse)
}

function confirmPaymentRedirection({paymentOrderId, paymentPoint}) {
  return client(
    `pay/order/${paymentOrderId}/confirm-execution`,
    {
      method: 'POST',
      token,
      paymentPoint,
    },
    apiURL,
  ).then(handlePaymentFlowFinished)
}

function getAvailablePaymentMethodTypes(applicationId) {
  return client(
    `payment-points/${applicationId}/payment-method-types`,
    {token},
    apiURL,
  )
}

export {
  anonymous,
  execute,
  threeDSAuthenticate,
  threeDsCompleteFingerprint,
  setPaymentOrder,
  getPaymentOrder,
  getAvailablePaymentMethodTypes,
  initPaymentRedirection,
  confirmPaymentRedirection,
}
