import React from 'react'

const VisaLogo = () => {
  return (
    <>
      <svg
        width="34"
        height="23"
        viewBox="0 0 34 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="34" height="23" rx="3" fill="#F5F5F5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8638 7.15921L14.9928 15.8639H12.73L14.6017 7.15921H16.8638ZM26.3827 12.7796L27.5738 9.5112L28.2594 12.7796H26.3827ZM28.9074 15.8639H31L29.1734 7.15921H27.2423C26.8079 7.15921 26.4417 7.4104 26.2791 7.79759L22.8843 15.8639H25.2595L25.7315 14.5634H28.634L28.9074 15.8639ZM23.0019 13.0216C23.012 10.7245 19.8101 10.5977 19.8321 9.57119C19.8391 9.2591 20.1378 8.92726 20.7918 8.84224C21.1155 8.80018 22.0095 8.76754 23.0223 9.23161L23.4199 7.38589C22.8751 7.18914 22.1749 7 21.3031 7C19.0669 7 17.4927 8.18335 17.4801 9.87722C17.4652 11.131 18.6035 11.8296 19.4611 12.2465C20.3422 12.673 20.6378 12.9467 20.6346 13.3282C20.6279 13.9125 19.9314 14.17 19.2803 14.1801C18.1428 14.1978 17.4826 13.8741 16.9566 13.6301L16.5469 15.5376C17.0751 15.7791 18.0509 15.9897 19.0629 16C21.4399 16 22.9945 14.8319 23.0019 13.0216ZM13.6308 7.15921L9.965 15.8639H7.57346L5.76954 8.91694C5.66 8.48925 5.56498 8.33223 5.23196 8.15238C4.68817 7.85835 3.79009 7.58277 3 7.41157L3.05328 7.15921H6.90341C7.3938 7.15921 7.83531 7.48394 7.94667 8.04659L8.89944 13.0838L11.2543 7.15921H13.6308Z"
          fill="#1D1C45"
        />
      </svg>
    </>
  )
}
export default VisaLogo
